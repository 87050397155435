
import { Vue, Component, Prop } from 'vue-property-decorator'

/**
 * 使用此组件在 template 中渲染 VNode
 *
 * @displayName VNode
 **/
@Component
export default class VNode extends Vue {
  /**
   * VNode
   **/
  @Prop() node: Vue.VNode

  render() {
    return this.node
  }
}
