

























































































































































































































import { Vue, Component, Ref } from 'vue-property-decorator'
import FengMap from '@/components/common/FengMap/index.vue'
import pageTitle from '@/components/common/pageTitle.vue'
import InputSearch from '@/components/common/InputSearch.vue'
import VNode from '@/components/common/VNode.vue'
import { variables } from '@/libs/theme'
import store, { actionTypes } from '@/store'
import _ from 'lodash'
@Component({
  name: 'index',
  components: {
    VNode,
    FengMap,
    pageTitle,
    InputSearch
  }
})
export default class MapPage extends Vue {
  @Ref() readonly fengmapNode: Vue
  private shadow9Color = variables.shadow9Color
  tabList = [
    {
      name: '地图编辑',
      index: 0
    },
    {
      name: '可视化配置',
      index: 1
    }
  ]
  tabIndex = 0
  filterText: string | null = null
  treeList: any[] = []
  groupId = 1
  showMap = false
  mapLoaded = false
  mapHeight = 750
  ViewMode = '2d'
  drawArea = {
    start: false,
    id: null,
    name: null,
    rendering: false,
    type: 'build'
  }
  updateTree: any[] = []
  form = {
    name: '',
    id: '',
    mapLabel: '',
    parentId: ''
  }
  rules = {
    name: [
      { required: true, message: '请输入名称', trigger: 'change' },
      { min: 1, max: 256, message: '长度不超过256个字符', trigger: 'change' }
    ]
  }
  showFlag = false
  currentMapLabel = null
  currentAreaName = null
  originAreaTree: any[] = []
  allRealtion: any = {}
  pointsList = []
  isSameName = false
  domPosition1 = {
    button: { x: 502, y: 12, type: 'RIGHT_BOTTOM' },
    groupControl: { x: 350, y: 12, type: 'RIGHT_BOTTOM' }
  }
  groupSpace: number | null = null
  saveGroupSpace: number | null = null
  saveSet: AnyObj = {}
  mapId = ''
  stepValue = 0
  isSuccessLoad = false
  firstLoad = true
  timer: number | null = null

  get domPosition() {
    return {
      button: { x: this.drawArea.rendering ? 140 : 494, y: 12, type: 'RIGHT_BOTTOM' },
      groupControl: { x: 338, y: 12, type: 'RIGHT_BOTTOM' }
    }
  }

  created() {
    this.tabIndex = 0
    this.showMap = false
    this.ViewMode = '2d'
    this.initParams()
    this.calTableHeight()
    window.addEventListener('resize', this.calTableHeight)
    this.firstLoad = true
  }
  deactivated() {
    if (this.timer) clearTimeout(this.timer)
    this.showFlag = false
  }
  activated() {
    if (!this.isSuccessLoad) {
      this.mapLoaded = false
      this.isTimeout()
    }
  }
  destroyed() {
    this.dispose()
    this.tabIndex = -1
    window.removeEventListener('resize', this.calTableHeight)
  }
  dispose() {
    this.fengmapNode.dispose()
  }
  watchInput(val: string) {
    if (val === '') {
      this.$nextTick(() => {
        this.filterText = null
        const editTreeNode: any = this.$refs.editTreeNode
        editTreeNode.search()
      })
    }
  }
  searchName(val: string) {
    this.filterText = val
    if (this.filterText === null || this.filterText.match(/^[ ]*$/)) {
      return false
    } else {
      if (this.treeList.length === 0) {
        this.getAreasList()
      }
      this.$nextTick(() => {
        const editTreeNode: any = this.$refs.editTreeNode
        editTreeNode.search(this.filterText)
      })
    }
  }
  isTimeout() {
    if (!this.mapLoaded) {
      this.showMap = true
      if (this.timer) clearTimeout(this.timer)
      this.timer = window.setTimeout(() => {
        if (!this.isSuccessLoad) {
          this.mapLoaded = true
          this.showMap = false
          this.message = this.$message({
            type: 'error',
            message: '地图加载失败，点击加载按钮重新加载。',
            customClass: 'is-underlying',
            duration: 0,
            showClose: true,
            clickButton: '加载',
            onClick: async () => {
              this.mapLoaded = false
              this.isTimeout()
              this.message.close()
            },
            createElement: this.$createElement
          })
        }
      }, 10000)
    }
  }
  changeTab(index: number) {
    this.firstLoad = false
    if (this.tabIndex !== index) {
      let opt = null
      let view = '3d'
      if (index === 0) {
        opt = this.isSuccessLoad && this.unSaved()
        view = '2d'
      } else {
        opt = this.updateTree.length > 0 || this.drawArea.rendering
        view = '3d'
      }
      if (opt) {
        this.$confirm('有更新未保存，确认离开？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          cancelButtonClass: 'message-cancel-btn'
        })
          .then(async () => {
            this.tabIndex = index
            this.ViewMode = view
            this.mapLoaded = false
            this.isSuccessLoad = false
            this.isTimeout()
            this.dispose()
            if (index === 1) {
              this.getMapConfig()
            }
            if (index === 0) {
              this.initParams()
            }
          })
          .catch(() => {})
      } else {
        this.tabIndex = index
        this.ViewMode = view
        this.mapLoaded = false
        this.isSuccessLoad = false
        this.isTimeout()
        this.dispose()
        if (index === 0) {
          this.initParams()
        }
        if (index === 1) {
          this.getMapConfig()
        }
      }
    }
  }
  async initParams() {
    this.$message.closeAll()

    this.updateTree = []
    this.pointsList = []
    this.drawArea = {
      start: false,
      id: null,
      name: null,
      rendering: false,
      type: 'build'
    }
    this.form = {
      name: '',
      id: '',
      mapLabel: '',
      parentId: ''
    }
    ;(this.currentMapLabel = null), (this.currentAreaName = null), (this.allRealtion = {})
    await this.getAreasList()
    if (this.filterText) {
      this.$nextTick(() => {
        const editTreeNode: any = this.$refs.editTreeNode
        editTreeNode.search(this.filterText)
      })
    }
  }
  saveChange() {
    if (this.drawArea.rendering) {
      this.$message({ message: '正在绘制中,不能保存,请结束绘制后保存！', type: 'warning' })
    } else {
      if (this.updateTree.length > 0) {
        this.updateTree.forEach(async (obj: any, index) => {
          const res = await this.$api.base.getEditAreaList(obj.id, obj)
          if (index === this.updateTree.length - 1) {
            if (res.data && res.data.code === 0) {
              this.$message({ message: '保存成功', type: 'success' })
              this.updateTree = [] // 保存成功后清空上次的修改
            }
          }
        })
      }
    }
  }
  stepBack() {
    if (this.pointsList.length > 0) {
      const fengmapNode: any = this.$refs.fengmapNode
      fengmapNode.stepBackDraw()
    }
  }
  drawRealPoint(val: any, groupId: number) {
    this.pointsList = val
    this.groupId = groupId
  }
  loadComplete() {
    this.mapLoaded = true
    this.isSuccessLoad = true
  }
  build(val: AnyObj) {
    this.drawArea.type = 'build'
    this.start(val)
  }
  relation(val: AnyObj) {
    this.drawArea.type = 'relation'
    this.start(val)
  }
  start(val: AnyObj) {
    this.form.id = val.id
    this.form.parentId = val.parentId
    this.drawArea.start = true
    this.drawArea.rendering = true
    this.drawArea.id = val.id
    this.drawArea.name = val.name
    this.drawAreaHandle()
  }
  handleMouseenter(data: any) {
    if (this.fengmapNode) this.fengmapNode.setHighlight(data.id, data.mapLabel)
  }
  handleMouseleave(data: any) {
    if (this.fengmapNode) this.fengmapNode.setHighlight(data.id, null)
  }
  stopRelation() {
    this.drawArea.start = false
    this.drawAreaHandle()
  }
  stopDrawArea() {
    this.drawArea.start = false
    this.pointsList = []
    this.drawAreaHandle()
  }
  drawAreaHandle() {
    if (this.fengmapNode) this.fengmapNode.drawArea(this.drawArea)
  }
  drawPoints(val: any, name: string) {
    //结束绘制
    if (val.length > 2) {
      if (this.form.id) {
        this.allRealtion[this.form.id] = val
      }
      let str = 'mark:'
      val.forEach((obj: any, index: number) => {
        if (index >= val.length - 2) {
          str = str + '(' + obj.x + ',' + obj.y + ');'
        } else {
          str = str + '(' + obj.x + ',' + obj.y + '),'
        }
      })
      this.form.mapLabel = str + val[0].groupID

      const temp: any = {}
      temp.id = this.form.id
      temp.parentId = this.form.parentId
      temp.mapLabel = this.form.mapLabel
      this.updateChangeList(temp)
      this.updateTreeArea(this.treeList, this.form.id, 'build')
      this.drawArea.rendering = false
    } else {
      if (val === 0) {
        this.$message({ message: '多边形的边不能相交', type: 'warning' })
      } else if (val === 1) {
        this.$confirm('当前存在未闭合多边形，结束绘制将无法保存该形状，是否结束?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          cancelButtonClass: 'message-cancel-btn'
        })
          .then(async () => {
            this.drawArea.rendering = false
            this.fengmapNode.drawArea(this.drawArea, 'clear')
            this.pointsList = []
          })
          .catch(() => {
            this.drawArea.rendering = true
          })
      } else if (val === 2) {
        this.$message({
          message: '当前模型已与区域' + name + '关联，请重新选择',
          type: 'warning'
        })
      } else {
        this.drawArea.rendering = false
        this.fengmapNode.drawArea(this.drawArea)
      }
    }
  }
  updateName(val: any, data: any) {
    this.form.name = data.name
    this.form.id = data.id
    this.form.parentId = data.parentId
    this.showFlag = true
  }
  getName(arr: any[], id: string, name: string) {
    let nameList = []
    arr.map(obj => {
      if (obj.id === id) {
        nameList = arr.map(i => {
          return i.name !== obj.name && i.name
        })
        this.isSameName = nameList.includes(name)
      }
      if (obj.children) {
        this.getName(obj.children, id, name)
      }
    })
  }
  submitName() {
    //修改名字
    const formNode: any = this.$refs.formNode
    formNode.validate(async (valid: any) => {
      if (valid) {
        this.getName(this.originAreaTree, this.form.id, this.form.name)
        if (!this.isSameName) {
          this.showFlag = false
          const temp: any = {}
          temp.id = this.form.id
          temp.name = this.form.name
          temp.parentId = this.form.parentId
          this.updateChangeList(temp)
          this.updateTreeArea(this.treeList, this.form.id, 'updateName')
          if (this.fengmapNode) this.fengmapNode.updateAreaName(this.form.id, this.form.name)
        } else {
          this.$message({ message: '同级区域不能重名', type: 'warning' })
        }
      }
    })
  }
  remove(val: any, data: any) {
    // 删除
    this.$confirm('模型删除后不可恢复，确认删除？', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
      cancelButtonClass: 'message-cancel-btn'
    })
      .then(() => {
        const temp: any = {}
        temp.id = data.id
        temp.mapLabel = null
        temp.parentId = data.parentId
        delete this.allRealtion[data.id]
        this.updateChangeList(temp)
        this.updateTreeArea(this.treeList, data.id, 'remove')
        if (this.fengmapNode) this.fengmapNode.removeArea(data.id)
      })
      .catch(() => {})
  }
  updateChangeList(val: AnyObj) {
    let flag = false
    this.updateTree.map((obj: any, index) => {
      if (obj.id === val.id) {
        this.getMapLabel(this.originAreaTree, val.id)
        Object.assign(obj, val)
        flag = true
        // 判断最终是否修改，，如果没有改动就移除updatelist
        if (obj.mapLabel !== undefined && obj.mapLabel === this.currentMapLabel) {
          if (
            (obj.name !== undefined && obj.name === this.currentAreaName) ||
            obj.name === undefined
          ) {
            this.updateTree.splice(index, 1)
          }
        }
        if (obj.mapLabel === undefined) {
          if (obj.name !== undefined && obj.name === this.currentAreaName) {
            this.updateTree.splice(index, 1)
          }
        }
      }
    })
    if (!flag) {
      this.updateTree.push(val)
    }
  }
  getMapLabel(arr: any[], id: string) {
    arr.map(obj => {
      if (obj.id === id) {
        this.currentMapLabel = obj.mapLabel
        this.currentAreaName = obj.name
      }
      if (obj.children) {
        this.getMapLabel(obj.children, id)
      }
    })
  }
  updateTreeArea(arr: any[], id: string, type: string) {
    arr.map(obj => {
      if (obj.id === id) {
        if (type === 'remove') {
          obj.mapLabel = null
        }
        if (type === 'updateName') {
          obj.name = this.form.name
        }
        if (type === 'build') {
          obj.mapLabel = this.form.mapLabel
        }
      }
      if (obj.children) {
        this.updateTreeArea(obj.children, id, type)
      }
    })
  }
  filter(value: string, data: any) {
    if (!value) return true
    return data.name.indexOf(value) !== -1
  }
  async getAreasList() {
    this.treeList = []
    const areas = await store.dispatch(actionTypes.FETCH_BASE_AREAS)

    if (areas) {
      this.treeList = areas
      this.originAreaTree = _.cloneDeep([areas])
      this.formatRelationArea([this.originAreaTree])
    }
  }
  formatRelationArea(arr: any[]) {
    arr.forEach(obj => {
      if (obj.mapLabel !== null) {
        this.allRealtion[obj.id] = obj.mapLabel
      }
      if (obj.children) {
        this.formatRelationArea(obj.children)
      }
    })
  }
  calTableHeight() {
    this.$nextTick(() => {
      this.mapHeight = window.innerHeight - 78
    })
  }
  changeValue(val: number | null) {
    this.groupSpace = val
  }
  async getMapConfig() {
    const { config } = this.$store.state
    if (config && config.map) {
      const res = config.map
      this.stepValue = res.groupSpace === null ? 40 : res.groupSpace
      this.saveGroupSpace = res.groupSpace
      this.groupSpace = res.groupSpace
      this.saveSet = Object.assign({}, res)
      this.mapId = res.id
    }
  }
  async saveConfig() {
    if (!this.isSuccessLoad) return
    let par = {}
    if (this.groupSpace !== null) {
      par = Object.assign(par, { groupSpace: Number(this.groupSpace) })
    }
    this.saveGroupSpace = this.groupSpace
    if (this.fengmapNode) this.fengmapNode.getMapSet()
    const control = (window as any).controlPose
    const { viewMode, scale, position, target, tiltAngle } = control
    if (control && viewMode === 4) {
      this.$message({ message: '请将地图切换到3D模式下再进行保存', type: 'error' })
    } else {
      if (control && position && position.x && position.y) {
        const params = {
          scale,
          target: target + ',' + tiltAngle,
          position: position.x + ',' + position.y
        }
        par = Object.assign(par, params)
        this.saveSet = Object.assign(this.saveSet, params)
        const res = await this.$api.base.updateMapInfo(this.mapId, par)
        if (res.data && res.data.code === 0) {
          this.$message({ message: '保存成功', type: 'success' })
        } else {
          this.$message({ message: '保存失败', type: 'error' })
        }
      } else {
        this.$message({ message: '保存失败', type: 'error' })
      }
    }
  }
  unSaved() {
    let flag = false
    if (this.groupSpace !== this.saveGroupSpace) {
      flag = true
    }

    if (this.fengmapNode) this.fengmapNode.getMapSet()
    const set = (window as any).controlPose
    if (set !== undefined && set !== null && set.position !== undefined) {
      const scale = set.scale
      const target = set.target + ',' + set.tiltAngle
      const position = set.position.x + ',' + set.position.y
      if (
        this.saveSet.scale !== scale ||
        this.saveSet.target !== target ||
        this.saveSet.position !== position
      ) {
        flag = true
      }
    }
    return flag
  }
}
